export const dropdownsData = (
    checkboxFiltersRadio,
    setCheckboxFiltersRadio
) => {
    return ([
    {
        type: 'checkbox',
        title: 'PROJECT TYPE',
        id: 'project_type',
        elements: [
            {
                text: 'Residential developments',
                id: 'residential_developments'
            },
            {
                text: 'Commercial developments',
                id: 'commercial_developments'
            },
            {
                text: 'Future Developments',
                id: 'future_developments'
            },
            {
                text: 'Asset Management',
                id: 'asset_management'
            }
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    },
    {
        type: 'checkbox',
        title: 'INFRASTRUCTURE',
        id: 'infrastructure',
        elements: [
            {
                text: 'Landmarks',
                id: 'landmarks'
            },
            {
                text: 'Transport (Metro)',
                id: 'transport'
            },
            {
                text: 'Education',
                id: 'education'
            },
            {
                text: 'Health',
                id: 'health'
            },
            {
                text: 'Retail or Shopping',
                id: 'retail_or_shopping'
            }
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    }
    ])
}